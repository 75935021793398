import React, {useState} from "react"
import { FaEnvelope } from 'react-icons/fa'
import { navigate } from 'gatsby'
import {useDispatch, useSelector} from "react-redux"

import "./union-member-single.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import {onChangeByName} from "../redux/registration/registrationAction"
import {checkMember} from "../redux/api/apiAction"
import {isDefined} from "../utils/data"
import HelpUr from "../components/help/help"

const UnionMemberSinglePage = () => {
    const dispatch  = useDispatch()
    const errorAPI  = useSelector(({apiReducer}) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [email, setEmail]     = useState("")
    const [validationEmail, setValidationEmail] = useState("")

    const onChange = e => {
        const {value} = e.target
        setEmail(value)
    }

    const checkValidation = () => {
        if(email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        return email.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if(checkValidation()) {
            dispatch(checkMember(email))
                .then(res => {
                    if(res === true) {
                        setEmail("")
                        setSuccess(true)
                        setError(false)
                        dispatch(onChangeByName("unionMember", true))
                        navigate("/non-member-single")
                    }
                    else {
                        setEmail(email)
                        setSuccess(false)
                        setError(true)
                        dispatch(onChangeByName("Delegate", true))
                        navigate("/non-member-single")
                    }
                })
        }
        else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
                <Layout>
            <SEO title="Registration" />
        <div className={"col-10 mx-auto"}>
                <form onSubmit={onSubmit}>
                    <div className="form-row">
                        <div className={"col-1"}>
                            <label><FaEnvelope/></label>
                        </div>
                        <div className={"col-11"}>
                            <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required/>
                        </div>
                    </div>
                    
                <div className={"content-footer"}>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
                </form>
            </div>
        </Layout>
    )
}

export default UnionMemberSinglePage
